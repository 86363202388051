import * as React from "react";
import { graphql, PageProps } from "gatsby";

// Components
import Layout from "components/Layout";
import Main from "components/Layout/Main";
import ArticleItem from "components/Sections/Articles/ArticleItem";
import ArticlesSidebar from "components/Sections/Articles/ArticlesSidebar";
import Pagination from "components/UIElements/Pagination";
// Model
import { IArticle } from "models/Article";
import { IOptionalMetaProps } from "models/Seo";

type DataProps = {
  articles: {
    nodes: IArticle[];
  };
  tags: { group: [{ slug: string; count: number }] };
};

type PageContextProps = {
  currentPage: number;
  totalPages: number;
};

const BlogIndex: React.FC<PageProps<DataProps, PageContextProps>> = ({
  data,
  pageContext,
}) => {
  const { currentPage, totalPages } = pageContext;
  const articles = data.articles.nodes;
  const tags = data.tags.group;

  const seo: IOptionalMetaProps = {
    title: "Artículos",
    description: "Lista de artículos publicados en la página",
  };

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <h2 className="title">Lista de artículos</h2>
        <div>
          {articles.map((article) => (
            <ArticleItem key={article.fields.slug} article={article} />
          ))}
        </div>
        <Pagination
          url="/articles"
          title="Artículos"
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Main>
      <ArticlesSidebar tags={tags} />
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query ArticlesListQuery($limit: Int!, $skip: Int!) {
    articles: allMdx(
      filter: {
        fields: { collection: { eq: "articles" } }
        frontmatter: { tags: { ne: "WIP" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          date(formatString: "DD [de] MMMM, YYYY", locale: "es")
          writer
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                width: 530
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }

    tags: allMdx(
      filter: {
        fields: { collection: { eq: "articles" } }
        frontmatter: { tags: { ne: "WIP" } }
      }
    ) {
      group(field: frontmatter___tags) {
        slug: fieldValue
        count: totalCount
      }
    }
  }
`;
